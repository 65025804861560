/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import DatePicker from 'react-datepicker';
import DashboardLineChart from "../../components/dashboard-line-chart";
import CheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';

const Dashboard = () => {
    const [welcomeModal, setWelcomeModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [activitySelected, setActivitySelected] = useState(false);
    const [stepSelected, setStepSelected] = useState('organization-details')

    return (
      <section className="dashboard">
        {/* BACKGROUND BANNER */}
        <div className="bg-blue-banner" />

        <div className="container">
          {/* PAGE HEADING */}
          <div className="page-head">
            <Row className="align-center">
              <Col lg="8" sm="12">
                <h1>Loyalty Platform Dashboard</h1>
                <p style={{ fontSize: "15px" }}>
                  Glance through your company's active campaigns and recent
                  interactions of users.
                </p>
              </Col>
              <Col lg="4" sm="12">
                <div className="d-flex resp-start">
                  <Button
                    className="import-btn"
                    onClick={() => setWelcomeModal(!welcomeModal)}
                  >
                    New Campaign
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          {/* DASHBOARD CARDS LAYOUT 1 */}
          <Row>
            <Col lg="3" sm="12">
              <Card className="create-campaign-card">
                <Card.Body className="create-campaign-cardbody">
                  <div
                    className="card-details"
                    style={{ paddingBottom: "5px" }}
                  >
                    <h2 className="total-sales">
                      69,700 <span>USD</span>
                    </h2>
                    <p>Total Rewards</p>
                  </div>
                  <DashboardLineChart />
                </Card.Body>
              </Card>
            </Col>
            <Col lg="3" sm="12">
              <Card className="new-users">
                <Card.Header className="new-users-header">
                  <h2 className="total-sales">100</h2>
                  <p>Total Customers</p>
                </Card.Header>
                <Card.Footer className="new-users-footer">
                  <p>Today's Customers</p>
                  <div className="d-flex">
                    <div className="user-avatars">AB</div>
                    <div className="user-avatars">AL</div>
                    <div className="user-avatars">JL</div>
                    <div className="user-avatars">JM</div>
                    <div className="user-avatars">FB</div>
                    <div className="user-avatars">CD</div>
                    <div className="user-avatars">GF</div>
                    <div className="more-avatars">+14</div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="6" sm="12">
              <Card className="create-report-card">
                <Card.Body className="create-report-cardbody">
                  <Row>
                    <Col lg="6" sm="12">
                      <h2>Start Campaign</h2>
                      <p>
                        Engage your existing customers, and increase their
                        interaction with your brand more frequently.
                      </p>
                      <Link to="/campaigns">
                        <Button className="start-campaign">
                          Start Campaign
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* DASHBOARD CARDS LAYOUT 2 */}
          <Row className="mt-4">
            <Col lg="4" sm="12">
              <Card className="social-network">
                <Card.Header className="social-network-header">
                  <h5>Social Network Stats</h5>
                  <p>8k social visitors</p>
                </Card.Header>
                <Card.Body className="social-network-body">
                  <div className="followers-count">
                    <div className="platform">
                      <img
                        src={require("../../assets/facebook-icon.png")}
                        alt="Facebook"
                      />
                      <div className="details">
                        <h5>RoundTechSquare Page</h5>
                        <p>Followers</p>
                      </div>
                    </div>
                    <h5>22K</h5>
                  </div>
                  <div className="followers-count">
                    <div className="platform">
                      <img
                        src={require("../../assets/instagram.png")}
                        alt="Instagram"
                      />
                      <div className="details">
                        <h5>RoundTechSquare</h5>
                        <p>Followers</p>
                      </div>
                    </div>
                    <h5>25K</h5>
                  </div>
                  <div className="followers-count">
                    <div className="platform">
                      <img
                        src={require("../../assets/linkedin.png")}
                        alt="Linkedin"
                      />
                      <div className="details">
                        <h5>RoundTechSquare</h5>
                        <p>Followers</p>
                      </div>
                    </div>
                    <h5>25K</h5>
                  </div>
                  <div
                    className="followers-count"
                    style={{ borderBottom: "0px solid #FFF" }}
                  >
                    <div className="platform">
                      <img
                        src={require("../../assets/twitter.png")}
                        alt="Twitter"
                      />
                      <div className="details">
                        <h5>RoundTechSquare</h5>
                        <p>Followers</p>
                      </div>
                    </div>
                    <h5>25K</h5>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="12">
              <Card className="activities-card">
                <Card.Header className="activities-card-header">
                  <div className="d-flex align-center justify-between">
                    <div>
                      <h5>Top Engagement Activities</h5>
                      <p>5 Active</p>
                    </div>
                    <Link
                      to="/activity"
                      style={{
                        fontSize: "14px",
                        color: "#323232",
                        fontWeight: "600",
                        textDecoration: "none",
                      }}
                    >
                      View All
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="activities-card-body">
                  <Row className="activity-name align-items-center">
                    <Col lg="8" sm="12">
                      <h5>Connect Facebook</h5>
                      <p>
                        Like and share on Facebook and follow our page on
                        Facebook
                      </p>
                    </Col>
                    <Col lg="4" sm="12">
                      <h5 className="points-details">30 points</h5>
                    </Col>
                  </Row>
                  <Row className="activity-name align-items-center">
                    <Col lg="8" sm="12">
                      <h5>Connect Facebook</h5>
                      <p>
                        Like and share on Facebook and follow our page on
                        Facebook
                      </p>
                    </Col>
                    <Col lg="4" sm="12">
                      <h5 className="points-details">30 points</h5>
                    </Col>
                  </Row>
                  <Row className="activity-name align-items-center mb-0">
                    <Col lg="8" sm="12">
                      <h5>Connect Twitter</h5>
                      <p>
                        Like and share on Facebook and follow our page on
                        Facebook
                      </p>
                    </Col>
                    <Col lg="4" sm="12">
                      <h5 className="points-details">30 points</h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="12">
              <Card className="transaction-card">
                <Card.Header className="transaction-card-header">
                  <div className="d-flex align-center justify-between">
                    <div>
                      <h5>Recent Transactions</h5>
                      <p>Total: 69,700 USD</p>
                    </div>
                    <Link
                      to="/rewards"
                      style={{
                        fontSize: "14px",
                        color: "#323232",
                        fontWeight: "600",
                        textDecoration: "none",
                      }}
                    >
                      View All
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="transaction-card-body">
                  <div className="followers-count">
                    <div className="platform">
                      <div className="avatar">AB</div>
                      <div className="details">
                        <h5>Abu Bin Ishtiyak</h5>
                        <p>admin1@shapesbrowbar.com</p>
                      </div>
                    </div>
                    <h5>$500K</h5>
                  </div>
                  <div className="followers-count">
                    <div className="platform">
                      <div className="avatar">AB</div>
                      <div className="details">
                        <h5>Abu Bin Ishtiyak</h5>
                        <p>admin1@shapesbrowbar.com</p>
                      </div>
                    </div>
                    <h5>$500K</h5>
                  </div>
                  <div className="followers-count">
                    <div className="platform">
                      <div className="avatar">AB</div>
                      <div className="details">
                        <h5>Abu Bin Ishtiyak</h5>
                        <p>admin1@shapesbrowbar.com</p>
                      </div>
                    </div>
                    <h5>$500K</h5>
                  </div>
                  <div
                    className="followers-count"
                    style={{ borderBottom: "0px solid #FFF" }}
                  >
                    <div className="platform">
                      <div className="avatar">AB</div>
                      <div className="details">
                        <h5>Abu Bin Ishtiyak</h5>
                        <p>admin1@shapesbrowbar.com</p>
                      </div>
                    </div>
                    <h5>$500K</h5>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        {/* WELCOME MODAL */}
        <Modal
          className="welcome-modal"
          centered
          show={welcomeModal}
          onHide={() => setWelcomeModal(!welcomeModal)}
          size="lg"
        >
          <Modal.Header className="welcome-modal-header">
            <h5>Getting Started</h5>
          </Modal.Header>
          <Modal.Body className="welcome-modal-body">
            <div className="body-heading">
              <h1>Welcome to Loyalty Platform!🎉</h1>
              <p>
                Let's start automating your customer engagement so you can{" "}
                <span>reclaim time and focus on your store growth.</span>
              </p>
            </div>
            {/* STEPWISE FORM */}
            <Row>
              <Col lg="12" sm="12">
                <form
                  className={
                    stepSelected === "organization-details"
                      ? "organization-form active"
                      : "organization-form"
                  }
                >
                  <Row>
                    <Col lg="12" sm="12">
                      <div className="form-group">
                        <label htmlFor="organizationLogo">
                          Organization Logo
                        </label>
                        <div className="upload-logo">
                          <div className="logo">
                            {/* <p>LP</p> */}
                            <img
                              src={require("../../assets/logo.webp")}
                              alt="Logo"
                            />
                          </div>
                          <input
                            type="file"
                            className="form-control logo-input"
                            onChange={(e) => console.log(e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg="6" sm="12">
                      <div className="form-group">
                        <label htmlFor="organizationName">
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="organizationName"
                          id="organizationName"
                          placeholder="RoundTechSquare"
                        />
                      </div>
                    </Col>
                    <Col lg="6" sm="12">
                      <div className="form-group">
                        <label htmlFor="organizationWebsite">
                          Organization Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="organizationWebsite"
                          id="organizationWebsite"
                          placeholder="https://www.roundtechsquare.com/"
                        />
                      </div>
                    </Col>
                    <Col lg="6" sm="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="phone">
                          Organization Phone
                        </label>
                        <PhoneInput
                          placeholder="Phone Number"
                          defaultCountry="US"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          className="phone-control"
                        />
                      </div>
                    </Col>
                    <Col lg="6" sm="12">
                      <div className="form-group">
                        <label htmlFor="organizationWebsite">
                          Organization Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="organizationEmail"
                          id="organizationEmail"
                          placeholder="contact@roundtechsquare.com"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
              </Col>
              <Col lg="12" sm="12">
                <form
                  className={
                    stepSelected === "default-activities"
                      ? "default-activities active"
                      : "default-activities"
                  }
                >
                  <Row>
                    <Col lg="12" sm="12">
                      <h5 className="head">Select the set of activities via which users can earn points on your platform.</h5>
                    </Col>
                    <Col lg="4" sm="12">
                      <Card className="activity-card">
                        <Card.Body className="activity-card-body">
                          <h5>Connect Facebook</h5>
                          <p>
                            Like and share on Facebook and follow our page on
                            Facebook
                          </p>
                        </Card.Body>
                        <div
                          className={
                            activitySelected
                              ? "radio-box d-none"
                              : "radio-box d-block"
                          }
                          onClick={() => setActivitySelected(!activitySelected)}
                        />
                        <CheckCircle
                          color="green"
                          size="22"
                          className={
                            activitySelected
                              ? "check-circle d-block"
                              : "check-cirlce d-none"
                          }
                          onClick={() => setActivitySelected(!activitySelected)}
                        />
                      </Card>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="welcome-modal-footer">
            <Button className="skip-btn" onClick={() => setWelcomeModal(!welcomeModal)}>Set up Later</Button>
            <Button className="next-btn" onClick={() => setStepSelected('default-activities')}>
              {stepSelected === 'default-activities' ? 'Save Details' : 'Proceed Next'}
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    );
};

export default Dashboard;