import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("agent/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`agent/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .put(`agent/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Events API
export const getEvent = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/events`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addEvent = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/events`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editEvent = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/events/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteEvent = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/events/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getEventByID = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/events/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs API
export const getBlog = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addBlog = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blog`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlog = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blog/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlog = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs Category API
export const getBlogCategories = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blogCategory`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addBlogCategory = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blogCategory`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlogCategory = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blogCategory/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlogCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blogCategory/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Agents API
export const getAgent = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`agent/user`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addAgents = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`agent/user`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editAgent = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`agent/user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteAgent = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`agent/user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//Contact-us
export const getContactUser = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contact-us`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addContactUser = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/contact-us`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editContactUser = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/contact-us/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteContactUser = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* RSVP */
export const getRSVPList = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/rsvp`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editRSVPMember = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/rsvp/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteRSVPMember = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/rsvp/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* HOME-EVULATION */
export const getHomeEvaluationList = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/home-evaluation`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteHomeEvaluationById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/home-evaluation/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editHomeEvaluationById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/home-evaluation/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* NEW-HOME-EVULATION */
export const getNewHomeEvaluationList = (status, sourceAgentId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(
        `admin/new-home-evaluation?status=` +
        status +
        `&sourceAgentId=` +
        sourceAgentId
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getNewHomeEvaluationStatusList = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/new-home-evaluation/status/getStatusList`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteNewHomeEvaluationById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/new-home-evaluation/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editNewHomeEvaluationById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/new-home-evaluation/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
