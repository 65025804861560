import React, { useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

/* API */
import { resetPassword } from "../../../service/api";

function UserDetails() {
  const user = useSelector((state) => state?.user);

  /* STATE VARIABLES */
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  /* API CALL */
  const handleChange = () => {
    if (newPassword.trim().length !== 0 && oldPassword.trim().length !== 0) {
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      resetPassword(data)
        .then((res) => {
          console.log("res -->", res);
          if (res?.status) {
            toast.success(res?.message);
            setOldPassword("");
            setNewPassword("");
          }
        })
        .catch((e) => {
          console.log("e -->", e?.response?.data?.message);
          toast.error(e?.response?.data?.message);
        });
    } else {
      if (newPassword.trim().length === 0) {
        toast.error("New Password cannot be empty");
      } else if (oldPassword.trim().length === 0) {
        toast.error("Old Password cannot be empty");
      }
    }
  };

  return (
    <section className="blogs">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>User Details</h1>
              <p style={{ fontSize: "15px" }}>
                View Personal Information and reset your password
              </p>
            </Col>
          </Row>
        </div>

        {/* USER DETAILS */}
        <div className="blogs-list">
          <Card className="list-card">
            <Card.Header className="list-cardheader w-100">
              <Row className="details-row w-100">
                <Col lg="6" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      type="text"
                      value={user?.user?.firstName}
                      placeholder="First Name"
                      className="form-control"
                      id="firstName"
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col lg="6" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      value={user?.user?.lastName}
                      placeholder="Last Name"
                      className="form-control"
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col lg="12" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="address">
                      Address
                    </label>
                    <input
                      type="text"
                      value={user?.user?.address}
                      placeholder="Your Address"
                      className="form-control"
                      id="address"
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col lg="6" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      type="text"
                      value={user?.user?.email}
                      id="email"
                      className="form-control"
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col lg="6" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="role">
                      Role
                    </label>
                    <input
                      type="text"
                      placeholder={user?.user?.role}
                      id="role"
                      className="form-control"
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="list-cardbody">
              <Row>
                <h4 style={{ fontSize: "18px", fontWeight: "700" }}>
                  CHANGE PASSWORD
                </h4>
                <Col lg="6" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="oldpass">
                      Old Password
                    </label>
                    <input
                      type="text"
                      id="oldpass"
                      placeholder="Enter your old password here"
                      className="form-control"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="6" className="details-col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="newpass">
                      New Password
                    </label>
                    <Form.Control
                      type="text"
                      id="newpass"
                      placeholder="Enter your new password"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="4" className="mt-1">
                  <Button
                    variant="primary"
                    className="change-button"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                    onClick={handleChange}
                  >
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  );
}

export default UserDetails;
