import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  CartesianGrid,
} from "recharts";

const data = [
  {
    name: "Jan 21, 2023",
    sales: 2910,
  },
  {
    name: "Jan 22, 2023",
    sales: 6420,
  },
  {
    name: "Jan 23, 2023",
    sales: 1110,
  },
  {
    name: "Jan 24, 2023",
    sales: 5180,
  },
  {
    name: "Jan 25, 2023",
    sales: 4400,
  },
  {
    name: "Jan 26, 2023",
    sales: 5170,
  },
  {
    name: "Jan 27, 2023",
    sales: 6460,
  },
  {
    name: "Jan 28, 2023",
    sales: 9830,
  },
  {
    name: "Jan 29, 2023",
    sales: 5290,
  },
  {
    name: "Jan 30, 2023",
    sales: 5430,
  },
  {
    name: "Jan 31, 2023",
    sales: 4690,
  },
  {
    name: "Feb 01, 2023",
    sales: 4350,
  },
];

const DashboardLineChart = () => {

    const CustomTooltip = () => {
      return (
        <div className="custom-tooltip">
          <div className="tooltip-header">
            <p>Jan 26</p>
          </div>
          <div className="tooltip-body">
            <p>
              <b>Rewards:</b> $2.5K
            </p>
          </div>
        </div>
      );
    };

    return (
      <ResponsiveContainer width="100%" height={120}>
        <LineChart
          className="line-chart"
          width={305}
          height={120}
          data={data}
        >
          <CartesianGrid strokeDasharray="3" vertical={false} horizontal={false} />
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone" dataKey="sales" stroke="#8884d8" strokeWidth={2} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    );
};

export default DashboardLineChart;