import actions from "../actions/userAction";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  event: [],
  blog: [],
  agent: [],
  contactUser:[],
};
const {
  SET_USER,
  SET_TOKEN,
  SET_LOGGEDIN,
  SET_LOADING,
  SET_EVENT,
  SET_BLOG,
  SET_AGENT,
  SET_CONTACTUSER,
} = actions;
const userReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_EVENT:
      return {
        ...state,
        event: data,
      };
    case SET_BLOG:
      return {
        ...state,
        blog: data,
      };
    case SET_AGENT:
      return {
        ...state,
        agent: data,
      };
    case SET_CONTACTUSER:
      return {
        ...state,
        contactUser: data,
      };
    default:
      return state;
  }
};

export default userReducer;
