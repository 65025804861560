import React from "react";

const AuthFooter = () => {
  return (
    <footer className="auth-footer">
      <div className="container">
        <div className="footer-options">
          <p>© 2024 Amberwood Real Estate Inc. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default AuthFooter;
