const actions = {
  SET_USER: "SET_USER",
  SET_TOKEN: "SET_TOKEN",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_LOADING: "SET_LOADING",
  SET_EVENT: "SET_EVENT",
  SET_BLOG: "SET_BLOG",
  SET_AGENT: "SET_AGENT",
  SET_CONTACTUSER:"SET_CONTACTUSER",
  setUser: (data) => {
    return { type: actions.SET_USER, data };
  },
  setToken: (data) => {
    return { type: actions.SET_TOKEN, data };
  },
  setLoggedin: (data) => {
    return { type: actions.SET_LOGGEDIN, data };
  },
  setLoading: (data) => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
  setEvent: (data) => {
    return {
      type: actions.SET_EVENT,
      data,
    };
  },
  setBlog: (data) => {
    return {
      type: actions.SET_BLOG,
      data,
    };
  },
  setAgent: (data) => {
    return {
      type: actions.SET_AGENT,
      data,
    };
  },
  setContactUser: (data) => {
    return {
      type: actions.SET_CONTACTUSER,
      data,
    };
  },
};

export default actions;
