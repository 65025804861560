/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

/* REDUX ACTIONS */
import {
  addAgents,
  deleteAgent,
  deleteEvent,
  editAgent,
  getAgent,
} from "../../../service/api";

/* ICON IMPORTS */
import PhoneIcon from "@iconscout/react-unicons/icons/uil-phone";
import EstateIcon from "@iconscout/react-unicons/icons/uil-estate";
import FacebookIcon from "@iconscout/react-unicons/icons/uil-facebook-f";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";
import WebsiteIcon from "@iconscout/react-unicons/icons/uil-globe";
import PreviousIcon from '@iconscout/react-unicons/icons/uil-previous';
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash";
import PlusIcon from "@iconscout/react-unicons/icons/uil-plus";

/* STYLES */
import "react-loading-skeleton/dist/skeleton.css";

/* CONFIG */
import { IMAGE_URL } from "../../../config";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  mobile: yup.string().required("Phone number is required."),
  email: yup.string().email().required("Email is required."),
  licence: yup.string().required("Licence is required."),
});

const AddAgent = () => {
  const [addAgent, setAddAgent] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(12);
  const [agentLoading, setAgentLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [totalAgent, setTotalAgent] = useState(0);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  /* GET AGENTS DATA */
  const getAgentData = (params) => {

    setAgentLoading(true);
    let obj = {}
    obj.page = page
    obj.sizePerPage = sizePerPage
    if (search) {
      if (params !== 'removeSearch') {
        obj.search = search
      }
    }
    getAgent(obj)
      .then((res) => {
        if (res.status) {
          setTotalAgent(res.data.totalDocs);
          setTotalPages(res?.data?.totalPages)
          setData(res.data.docs);
        }
      })
      .finally((res) => {
        setAgentLoading(false);
      });
  };

  /* DELETE AGENTS DATA */
  const handleDelete = (id) => {
    setLoading(true);
    deleteAgent(id)
      .then((res) => {
        if (res?.status) {
          getAgentData();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  /* USE EFFECT */
  useEffect(() => {
    getAgentData();
  }, [page, sizePerPage]);

  const AddModalComponent = () => {
    const [file, setFile] = useState(null);
    const [aboutData, setAboutData] = useState();

    /* FORM VALUES HANDLING */
    const formik = useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobile: "",
        fax: "",
        office: "",
        about: "",
        licence: "",
        image: "",
        fbUrl: "",
        instaUrl: "",
        twitterUrl: "",
        linkedInUrl: "",
        webUrl: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        let formData = new FormData();
        formData.append("firstName", values?.firstName);
        formData.append("lastName", values?.lastName);
        formData.append("email", values?.email);
        formData.append("mobile", values?.mobile);
        if (values?.fax) {
          formData.append("fax", values?.fax)
        }
        if (values?.office) {
          formData.append("office", values?.office)
        }
        if (values?.about) {
          formData.append("about", values?.about)
        }

        if (values?.licence) {
          formData.append("licence", values?.licence)
        }

        if (values?.fbUrl) {
          formData.append("fbUrl", values?.fbUrl)
        }

        if (values?.instaUrl) {
          formData.append("instaUrl", values?.instaUrl)
        }

        if (values?.twitterUrl) {
          formData.append("twitterUrl", values?.twitterUrl)
        }

        if (values?.linkedInUrl) {
          formData.append("linkedInUrl", values?.linkedInUrl)
        }
        if (values?.webUrl) {
          formData.append("webUrl", values?.webUrl)
        }
        if (file) {
          formData.append("image", file);
        }
        setLoading(true);
        addAgents(formData)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              setTimeout(() => {
                setAddAgent(false);
                getAgentData();
                setFile(null);
                setPreviewUrl(null);
                formik.resetForm();
              }, 1500)
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            setLoading(false);
          });
      }
    });

    /* HANDLE IMAGE CHANGE */
    const handleImageChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      formik.handleChange(e);
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
      } else {
        setPreviewUrl(null);
      }
      e.target.value = "";
    };

    /* HANDLE DELETE AGENT */
    const handleDeleteClick = () => {
      setFile(null);
      setPreviewUrl(null);
    };

    return (
      <Modal
        centered
        size="lg"
        show={addAgent}
        onHide={() => setAddAgent(!addAgent)}
      >
        <form className="add-agent-form" onSubmit={formik.handleSubmit}>
          <Modal.Header className="add-agent-modal-header">
            <h5>Add Agent</h5>
          </Modal.Header>
          <Modal.Body className="add-agent-modal-body">
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="image">
                    Profile photo
                  </label>
                  <div className="profile-img-agent">
                    <div className="custom-file-input-css">
                      <div className="img-selection-box">
                        <div>
                          <ImportIcon size="20" color="#323232" />
                          <p>Upload from local computer</p>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="image-control"
                        name="image"
                        id="image"
                        placeholder="Reward Name"
                        onChange={handleImageChange}
                      />
                    </div>

                    {previewUrl ? (
                      previewUrl.startsWith("data:image") ? (
                        <div className="uploaded-image">
                          <img src={previewUrl} alt="Profile" />
                          <div className="action-btns">
                            <Button
                              className="remove-btn"
                              onClick={handleDeleteClick}
                            >
                              <TrashIcon size="15" color="#323232" />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="uploaded-image">
                          <img src={`${previewUrl}`} alt="Profile" />
                          <div className="action-btns">
                            <Button
                              className="remove-btn"
                              onClick={handleDeleteClick}
                            >
                              <TrashIcon size="15" color="#323232" />
                            </Button>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                  {formik.errors.image && formik.touched.image && (
                    <small style={{ color: "red" }}>
                      {formik.errors.image}
                    </small>
                  )}
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="firstName">
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.firstName && formik.touched.firstName
                        ? "validation-border"
                        : ""
                    }`}
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="lName">
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.lastName && formik.touched.lastName
                        ? "validation-border"
                        : ""
                    }`}
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      formik.errors.email && formik.touched.email
                        ? "validation-border"
                        : ""
                    }`}
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="mobile">
                    Phone <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.mobile && formik.touched.mobile
                        ? "validation-border"
                        : ""
                    }`}
                    name="mobile"
                    id="mobile"
                    placeholder="Phone"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="emailAddress">
                    Fax
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.fax && formik.touched.fax
                        ? "validation-border"
                        : ""
                    }`}
                    name="fax"
                    id="fax"
                    placeholder="Fax Number"
                    value={formik.values.fax}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="emailAddress">
                    DRE License Number <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.licence && formik.touched.licence
                        ? "validation-border"
                        : ""
                    }`}
                    name="licence"
                    id="licence"
                    placeholder="DRE License Number"
                    value={formik.values.licence}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="about">
                    About
                  </label>
                  <textarea
                    rows="4"
                    className={`form-control ${
                      formik.errors.about && formik.touched.about
                        ? "validation-border"
                        : ""
                    }`}
                    name="about"
                    id="about"
                    placeholder="Short information about agent"
                    value={formik.values.about}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fbUrl">
                    Facebook Profile URL
                  </label>
                  <div className="p-relative">
                    <input
                      type="text"
                      className={`form-control social-control ${
                        formik.errors.fbUrl && formik.touched.fbUrl
                          ? "validation-border"
                          : ""
                      }`}
                      name="fbUrl"
                      id="fbUrl"
                      placeholder="facebook.com/amberwoodre"
                      value={formik.values.fbUrl}
                      onChange={formik.handleChange}
                    />
                    <FacebookIcon
                      size="16"
                      color="#1877F2"
                      className="social-icon"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="instaUrl">
                    Instagram Profile URL
                  </label>
                  <div className="p-relative">
                    <input
                      type="text"
                      className={`form-control social-control ${
                        formik.errors.instaUrl && formik.touched.instaUrl
                          ? "validation-border"
                          : ""
                      }`}
                      name="instaUrl"
                      id="instaUrl"
                      placeholder="instagram.com/amberwoodre"
                      value={formik.values.instaUrl}
                      onChange={formik.handleChange}
                    />
                    <InstagramIcon
                      size="16"
                      color="#e60f43"
                      className="social-icon"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="twitterUrl">
                    Twitter Profile URL
                  </label>
                  <div className="p-relative">
                    <input
                      type="text"
                      className={`form-control social-control ${
                        formik.errors.twitterUrl && formik.touched.firstName
                          ? "validation-border"
                          : ""
                      }`}
                      name="twitterUrl"
                      id="twitterUrl"
                      placeholder="twitter.com/amberwoodre"
                      value={formik.values.twitterUrl}
                      onChange={formik.handleChange}
                    />
                    <TwitterIcon
                      size="16"
                      color="#1D9BF0"
                      className="social-icon"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="linkedInUrl">
                    Linkedin Profile URL
                  </label>
                  <div className="p-relative">
                    <input
                      type="text"
                      className={`form-control social-control ${
                        formik.errors.linkedInUrl && formik.touched.linkedInUrl
                          ? "validation-border"
                          : ""
                      }`}
                      name="linkedInUrl"
                      id="linkedInUrl"
                      placeholder="linkedin.com/amberwoodre"
                      value={formik.values.linkedInUrl}
                      onChange={formik.handleChange}
                    />
                    <LinkedinIcon
                      size="16"
                      color="#0077B7"
                      className="social-icon"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="webUrl">
                    Website URL
                  </label>
                  <div className="p-relative">
                    <input
                      type="text"
                      className={`form-control social-control ${
                        formik.errors.webUrl && formik.touched.webUrl
                          ? "validation-border"
                          : ""
                      }`}
                      name="webUrl"
                      id="webUrl"
                      placeholder="Website URL"
                      value={formik.values.webUrl}
                      onChange={formik.handleChange}
                    />
                    <WebsiteIcon
                      size="16"
                      color="#323232"
                      className="social-icon"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => {
                formik.resetForm();
                setAddAgent(!addAgent);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="proceed-btn" disabled={loading}>
              {loading ? <Spinner /> : "Add Agent"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  const AgentCard = (props) => {
    const [editAgentModal, setEditAgentModal] = useState(false);

    const EditComponent = (agentDetails) => {
      const [loading, setLoading] = useState(false);
      const [file, setFile] = useState(null);
      const [previewUrl, setPreviewUrl] = useState(null);
      const [agentDetail, setAgentDetails] = useState(agentDetails?.data);

      /* FORM VALUES HANDLING */
      const formik = useFormik({
        initialValues: {
          firstName: agentDetail?.firstName,
          lastName: agentDetail?.lastName,
          email: agentDetail?.email,
          mobile: agentDetail?.mobile,
          fax: agentDetail?.fax,
          office: agentDetail?.office,
          about: agentDetail?.about,
          licence: agentDetail?.licence,
          image: "",
          fbUrl: agentDetail?.social?.fbUrl,
          instaUrl: agentDetail?.social?.instaUrl,
          twitterUrl: agentDetail?.social?.twitterUrl,
          linkedInUrl: agentDetail?.social?.linkedInUrl,
          webUrl: agentDetail?.social?.webUrl,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          let formData = new FormData();
          formData.append("firstName", values?.firstName);
          formData.append("lastName", values?.lastName);
          formData.append("email", values?.email);
          formData.append("mobile", values?.mobile);
          if (values?.fax) {
            formData.append("fax", values?.fax);
          }
          if (values?.office) {
            formData.append("office", values?.office);
          }
          if (values?.about) {
            formData.append("about", values?.about);
          }

          if (values?.licence) {
            formData.append("licence", values?.licence);
          }

          if (values?.fbUrl) {
            formData.append("fbUrl", values?.fbUrl);
          }

          if (values?.instaUrl) {
            formData.append("instaUrl", values?.instaUrl);
          }

          if (values?.twitterUrl) {
            formData.append("twitterUrl", values?.twitterUrl);
          }

          if (values?.linkedInUrl) {
            formData.append("linkedInUrl", values?.linkedInUrl);
          }
          if (values?.webUrl) {
            formData.append("webUrl", values?.webUrl);
          }
          if (file) {
            formData.append("image", file);
          } else {
            formData.append("IMAGE_URL", agentDetail?.image);
          }
          setLoading(true);
          editAgent(agentDetail?._id, formData)
            .then((res) => {
              if (res?.status) {
                toast.success(res?.message);
                setTimeout(() => {
                  setEditAgentModal(false);
                  setFile(null);
                  setPreviewUrl(null);
                  formik.resetForm();
                  getAgentData();
                }, 1500);
              } else {
                toast.error(res?.message);
              }
            })
            .catch((e) => {
              setLoading(false);
              toast.error(e?.response?.data?.message);
            })
            .finally((res) => {
              setLoading(false);
            });
        },
      });

      /* HANDLE IMAGE CHANGE */
      const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        formik.handleChange(e);
        if (selectedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onloadend = () => {
            setPreviewUrl(reader.result);
          };
        } else {
          setPreviewUrl(null);
        }

        e.target.value = "";
      };

      /* HANDLE DELETE AGENT */
      const handleDeleteClick = () => {
        setFile(null);
        setPreviewUrl(null);
      };

      return (
        <Modal
          centered
          size="lg"
          show={editAgentModal}
          onHide={() => setEditAgentModal(!editAgentModal)}
        >
          <form className="add-agent-form" onSubmit={formik.handleSubmit}>
            <Modal.Header className="add-agent-modal-header">
              <h5>Edit Agent</h5>
            </Modal.Header>
            <Modal.Body className="add-agent-modal-body">
              <Row>
                <Col lg="12" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="image">
                      Profile photo
                    </label>
                    <div className="profile-img-agent">
                      <div className="custom-file-input-css">
                        <div className="img-selection-box">
                          <div>
                            <ImportIcon size="20" color="#323232" />
                            <p>Upload from local computer</p>
                          </div>
                        </div>
                        <input
                          type="file"
                          className="image-control"
                          name="image"
                          id="image"
                          placeholder="Reward Name"
                          onChange={handleImageChange}
                        />
                      </div>

                      {previewUrl ? (
                        previewUrl.startsWith("data:image") ? (
                          <div className="uploaded-image">
                            <img src={previewUrl} alt="Profile" />
                            <div className="action-btns">
                              <Button
                                className="remove-btn"
                                onClick={handleDeleteClick}
                              >
                                <TrashIcon size="15" color="#323232" />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="uploaded-image">
                            <img src={`${previewUrl}`} alt="Profile" />
                            <div className="action-btns">
                              <Button
                                className="remove-btn"
                                onClick={handleDeleteClick}
                              >
                                <TrashIcon size="15" color="#323232" />
                              </Button>
                            </div>
                          </div>
                        )
                      ) : null}

                      {agentDetails?.data?.image === null ? (
                        <></>
                      ) : (
                        <div className={`uploaded-image ${previewUrl ? "d-none" : 'd-block'}`}>
                          <img
                            src={IMAGE_URL + agentDetails?.data?.image}
                            alt="Uploaded Profile"
                          />
                        </div>
                      )}
                    </div>
                    {formik.errors.image && formik.touched.image && (
                      <small style={{ color: "red" }}>
                        {formik.errors.image}
                      </small>
                    )}
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="firstName">
                      First Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.firstName && formik.touched.firstName
                          ? "validation-border"
                          : ""
                      }`}
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="lName">
                      Last Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.lastName && formik.touched.lastName
                          ? "validation-border"
                          : ""
                      }`}
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email Address <span>*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        formik.errors.email && formik.touched.email
                          ? "validation-border"
                          : ""
                      }`}
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="mobile">
                      Phone <span>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.mobile && formik.touched.mobile
                          ? "validation-border"
                          : ""
                      }`}
                      name="mobile"
                      id="mobile"
                      placeholder="Phone"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="emailAddress">
                      Fax
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.fax && formik.touched.fax
                          ? "validation-border"
                          : ""
                      }`}
                      name="fax"
                      id="fax"
                      placeholder="Fax Number"
                      value={formik.values.fax}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="emailAddress">
                      DRE License Number <span>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.licence && formik.touched.licence
                          ? "validation-border"
                          : ""
                      }`}
                      name="licence"
                      id="licence"
                      placeholder="DRE License Number"
                      value={formik.values.licence}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="12" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="about">
                      About
                    </label>
                    <textarea
                      rows="4"
                      className={`form-control ${
                        formik.errors.about && formik.touched.about
                          ? "validation-border"
                          : ""
                      }`}
                      name="about"
                      id="about"
                      placeholder="Short information about agent"
                      value={formik.values.about}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fbUrl">
                      Facebook Profile URL
                    </label>
                    <div className="p-relative">
                      <input
                        type="text"
                        className={`form-control social-control ${
                          formik.errors.fbUrl && formik.touched.fbUrl
                            ? "validation-border"
                            : ""
                        }`}
                        name="fbUrl"
                        id="fbUrl"
                        placeholder="facebook.com/amberwoodre"
                        value={formik.values.fbUrl}
                        onChange={formik.handleChange}
                      />
                      <FacebookIcon
                        size="16"
                        color="#1877F2"
                        className="social-icon"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="instaUrl">
                      Instagram Profile URL
                    </label>
                    <div className="p-relative">
                      <input
                        type="text"
                        className={`form-control social-control ${
                          formik.errors.instaUrl && formik.touched.instaUrl
                            ? "validation-border"
                            : ""
                        }`}
                        name="instaUrl"
                        id="instaUrl"
                        placeholder="instagram.com/amberwoodre"
                        value={formik.values.instaUrl}
                        onChange={formik.handleChange}
                      />
                      <InstagramIcon
                        size="16"
                        color="#e60f43"
                        className="social-icon"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="twitterUrl">
                      Twitter Profile URL
                    </label>
                    <div className="p-relative">
                      <input
                        type="text"
                        className={`form-control social-control ${
                          formik.errors.twitterUrl && formik.touched.firstName
                            ? "validation-border"
                            : ""
                        }`}
                        name="twitterUrl"
                        id="twitterUrl"
                        placeholder="twitter.com/amberwoodre"
                        value={formik.values.twitterUrl}
                        onChange={formik.handleChange}
                      />
                      <TwitterIcon
                        size="16"
                        color="#1D9BF0"
                        className="social-icon"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="linkedInUrl">
                      Linkedin Profile URL
                    </label>
                    <div className="p-relative">
                      <input
                        type="text"
                        className={`form-control social-control ${
                          formik.errors.linkedInUrl &&
                          formik.touched.linkedInUrl
                            ? "validation-border"
                            : ""
                        }`}
                        name="linkedInUrl"
                        id="linkedInUrl"
                        placeholder="linkedin.com/amberwoodre"
                        value={formik.values.linkedInUrl}
                        onChange={formik.handleChange}
                      />
                      <LinkedinIcon
                        size="16"
                        color="#0077B7"
                        className="social-icon"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="webUrl">
                      Website URL
                    </label>
                    <div className="p-relative">
                      <input
                        type="text"
                        className={`form-control social-control ${
                          formik.errors.webUrl && formik.touched.webUrl
                            ? "validation-border"
                            : ""
                        }`}
                        name="webUrl"
                        id="webUrl"
                        placeholder="Website URL"
                        value={formik.values.webUrl}
                        onChange={formik.handleChange}
                      />
                      <WebsiteIcon
                        size="16"
                        color="#323232"
                        className="social-icon"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="add-user-modal-footer">
              <Button
                className="cancel-btn"
                onClick={() => {
                  formik.resetForm();
                  setEditAgentModal(!editAgentModal);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" className="proceed-btn" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Edit Agent"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
    };

    return (
      <>
        <div className="agent-card">
          <div className="agent-profile-image">
            <img
              src={
                props.agentImage
                  ? IMAGE_URL + props.agentImage
                  : "https://storage.googleapis.com/cdn-amberwoodre/images/agent-placeholder.png"
              }
              alt={props.firstName}
            />
          </div>
          <div className="agent-details">
            <h5>
              {props.firstName} {props.lastName}
            </h5>
            <div className="d-flex align-items-center">
              <Link
                href={`tel:${props.telephone}`}
                className="d-flex align-items-center telephone"
              >
                <PhoneIcon size="18" color="#323232" />
                <p>{props.telephone}</p>
              </Link>
              <div className="d-flex align-items-center dre-number">
                <EstateIcon size="18" color="#323232" />
                <p>DRE# {props.realEstateNumber}</p>
              </div>
            </div>
            <div className="d-flex align-items-center engagement-btns">
              <Button
                className="view-profile-btn"
                onClick={() => {
                  setEditAgentModal(!editAgentModal);
                }}
              >
                Edit Profile
              </Button>
              <Button
                className="contact-btn"
                onClick={() => {
                  handleDelete(props?.agentDetails?._id);
                }}
              >
                Remove Agent
              </Button>
            </div>
          </div>
        </div>
        <EditComponent data={props?.agentDetails} />
      </>
    );
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    setLoading(true)
    setData([])
    setPage(selectedPage);
    console.log(`Page ${selectedPage} clicked.`);
  }

  return (
    <section className="add-agent">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Amberwood Agents</h1>
              <p style={{ fontSize: "15px" }}>
                Add and edit agents in the Amberwood Real Estate website.
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                <Button
                  className="add-btn"
                  onClick={() => {
                    setAddAgent(!addAgent);
                  }}
                >
                  <PlusIcon size="16" color="#323232" />
                  Add Agent
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* AGENTS LIST */}
        <div className="agents-list">
          <Card className="list-card">
            <Card.Header className="list-cardheader">
              <p>
                Total <b>{totalAgent}</b> agents
              </p>
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Agent"
                  name="searchUser"
                  id="searchUser"
                  value={search}
                  onChange={(e) => {
                    setSearch(e?.target?.value.charAt(0).toUpperCase() + e.target?.value.slice(1))
                  }}
                />

                <Button onClick={() => { getAgentData() }} className="view-profile-btn mx-2 mt-0">
                  Search
                </Button>
                <Button onClick={() => { setSearch(''); getAgentData('removeSearch') }} className="contact-btn mt-0">
                  Reset
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="list-cardbody">
              <div className="row gy-4">
                {agentLoading ? (
                  <>
                    <div className="col-xl-4 col-sm-12">
                      <div className="agent-card">
                        <Skeleton className="image-loading" />
                        <div className="agent-details">
                          <h5>
                            <Skeleton className="name-loading" />
                          </h5>
                          <div className="d-flex align-items-center">
                            <Link className="d-flex align-items-center telephone">
                              <PhoneIcon size="18" color="#323232" />
                              <Skeleton className="phone-loading" />
                            </Link>
                            <div className="d-flex align-items-center dre-number">
                              <EstateIcon size="18" color="#323232" />
                              <Skeleton className="re-number-loading" />
                            </div>
                          </div>
                          <div className="d-flex align-items-center engagement-btns">
                            <Button className="view-profile-btn">
                              Edit Profile
                            </Button>
                            <Button className="contact-btn">
                              Remove Agent
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="agent-card">
                        <Skeleton className="image-loading" />
                        <div className="agent-details">
                          <h5>
                            <Skeleton className="name-loading" />
                          </h5>
                          <div className="d-flex align-items-center">
                            <Link className="d-flex align-items-center telephone">
                              <PhoneIcon size="18" color="#323232" />
                              <Skeleton className="phone-loading" />
                            </Link>
                            <div className="d-flex align-items-center dre-number">
                              <EstateIcon size="18" color="#323232" />
                              <Skeleton className="re-number-loading" />
                            </div>
                          </div>
                          <div className="d-flex align-items-center engagement-btns">
                            <Button className="view-profile-btn">
                              Edit Profile
                            </Button>
                            <Button className="contact-btn">
                              Remove Agent
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="agent-card">
                        <Skeleton className="image-loading" />
                        <div className="agent-details">
                          <h5>
                            <Skeleton className="name-loading" />
                          </h5>
                          <div className="d-flex align-items-center">
                            <Link className="d-flex align-items-center telephone">
                              <PhoneIcon size="18" color="#323232" />
                              <Skeleton className="phone-loading" />
                            </Link>
                            <div className="d-flex align-items-center dre-number">
                              <EstateIcon size="18" color="#323232" />
                              <Skeleton className="re-number-loading" />
                            </div>
                          </div>
                          <div className="d-flex align-items-center engagement-btns">
                            <Button className="view-profile-btn">
                              Edit Profile
                            </Button>
                            <Button className="contact-btn">
                              Remove Agent
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  data?.length === 0 ? (
                    <p style={{ textAlign: 'center', fontWeight: '600', fontSize: '14px', marginBottom: '0px' }}>No agents found</p>
                  ) : (
                    data.map((agent, _id) => {
                      return (
                        <div className="col-xl-4 col-sm-12" key={_id}>
                          <AgentCard
                            firstName={agent.firstName}
                            lastName={agent.lastName}
                            agentImage={agent.image}
                            telephone={agent.mobile}
                            realEstateNumber={agent.licence}
                            handleDelete={handleDelete}
                            data={agent}
                            addAgent={addAgent}
                            setAddAgent={setAddAgent}
                            loading={loading}
                            agentDetails={agent}
                            previewUrl={previewUrl}
                          />
                        </div>
                      );
                    })
                  )

                )}
              </div>
            </Card.Body>
            <Card.Footer
              className={`list-cardfooter ${agentLoading ? "d-none" : "d-block"
                }`}
            >
              {/* REACT PAGINATION */}
              <ReactPaginate
                className="blogs-pagination"
                previousLabel={
                  <Button className="blog-pagination-btn">
                    <PreviousIcon color="#323232" size="20" />
                  </Button>
                }
                nextLabel={
                  <Button className="blog-pagination-btn">
                    <NextIcon color="#323232" size="20" />
                  </Button>
                }
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                activeClassName={"active"}
              />
            </Card.Footer>
          </Card>
        </div>

        <AddModalComponent />

      </div>
    </section>
  );
};

export default AddAgent;
