/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { ContentState, convertToRaw, EditorState, convertFromHTML } from "draft-js";

/* APIS IMPORT */
import { getBlogCategories, addBlog } from '../../../service/api'
import { IMAGE_URL } from "../../../config";

/* ICON IMPORTS */
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import { Link } from "react-router-dom";


const BlogDetail = () => {
  const [images, setImages] = useState([]);
  const [blogDesc, setBlogDesc] = useState();
  const [loading, setLoading] = useState(true);
  const [blogCategories, setBlogCategories] = useState([])
  const [labelOptions, setLabelOptions] = useState([])

  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);

  useEffect(() => {
    getBlogsCategoryListFunction()
  }, []);

  /* GET BLOGS LISTING */
  const getBlogsCategoryListFunction = () => {
    setLoading(true);
    getBlogCategories().then((res) => {
      if (res?.status) {
        setBlogCategories(res?.data)
        // setT(res?.data?.totalDocs)
      }
      setLoading(false)
    })
      .catch(err => {
        console.log("err", err)
      })
      .finally(err => {
        setLoading(false)
      })

    return (() => {
      setBlogCategories([])
    })
  }
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    discription: yup.string().required("Description is required."),
    category: yup.array().required("Category Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      discription: "<p></p>\n",
      image: "",
      category: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
      let formData = new FormData();
      formData.append("name", values?.name);
      formData.append("discription", values?.discription);
      console.log(images)
      if (images){
        formData.append("image", images);
      }
      // formData.append("addedBy", values?.addedBy);
      // formData.append("isShow", values?.isShow);
      // formData.append("startDate", values?.startDate);
      // formData.append("endDate", values?.endDate);
      if (file1) formData.append("addedByImage", file1);
      const tempArray = []
      values?.category.forEach(element => {
        tempArray.push(element?.value)
      });
      formData.append("category", tempArray);

      setLoading(true);

      addBlog(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            // setAddBlogModal(false);
            // getBlogData();
            // setFile(null);
            // setFile1(null);
            // setPreviewUrl(null);
            // setPreviewUrl1(null);
            formik.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (blogCategories?.length > 0) {
      let tempArray = []
      blogCategories.forEach(element => {
        console.log(element)
        tempArray.push({ value: element?._id, label: element?.name },)
      })
      setLabelOptions(tempArray)
    }
  }, [blogCategories])

  const handleProfileImage = (imageList) => {
    setImages(imageList);
  };

  return (
    <section className="blog-detail">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>How to Choose a Real Estate Agency to Work For</h1>
            </Col>
          </Row>
        </div>

        {/* BLOG DETAIL */}
        <div className="details">
          <Card className="blog-card">
            <form onSubmit={formik.handleSubmit} >
              <Card.Body className="blog-cardbody">
                <div className="row">
                  <div className="col-xl-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fName">
                        Blog Photo <span>*</span>
                      </label>
                      <ImageUploading
                        value={images}
                        onChange={(e) => { handleProfileImage(e) }}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemove,
                          onImageUpdate,
                        }) => (
                          <div className="agent-profile-img">
                            <div
                              className="img-selection-box"
                              onClick={onImageUpload}
                            >
                              <div>
                                <ImportIcon size="20" color="#323232" />
                                <p>Upload from local computer</p>
                              </div>
                            </div>
                            {imageList.map((image, index) => (
                              <div key={index} className="uploaded-image">
                                <img
                                  src={image["data_url"]}
                                  alt="Uploaded Profile"
                                />
                                <div className="action-btns">
                                  <Button
                                    className="edit-btn"
                                    onClick={onImageUpdate}
                                  >
                                    <EditIcon size="15" color="#323232" />
                                  </Button>
                                  <Button
                                    className="remove-btn"
                                    onClick={onImageRemove}
                                  >
                                    <TrashIcon size="15" color="#323232" />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Blog Title <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="How to choose a real estate agency to work for"
                        name="name"
                        id="blogTitle"
                        value={formik.values.name}
                        onChange={formik.handleChange}

                      />
                      {formik.errors.name && formik.touched.name && (
                        <small style={{ color: "red" }}>
                          {formik.errors.name}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Blog Labels <span>*</span>
                      </label>
                      <Select
                        isMulti
                        options={labelOptions}
                        className="react-select"
                        name="category"
                        placeholder="Labels"
                        classNamePrefix="action-select"
                        value={formik.values.category}
                        onChange={(e) => formik.setFieldValue('category', e)}
                      />
                      {formik.errors.category && formik.touched.category && (
                        <small style={{ color: "red" }}>
                          {formik.errors.category}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 col-sm-12">
                    <div className="form-group mb-0">
                      <label className="form-label">
                        Blog Description <span>*</span>
                      </label>
                      <Editor
                      
                        editorClassName="texteditor-control"
                        toolbarClassName="toolbar-control"
                        placeholder="Description about the blog"
                        editorState={blogDesc}

                        onEditorStateChange={(e) => {
                          setBlogDesc(e);
                          formik.setFieldValue("discription", draftToHtml(convertToRaw(e.getCurrentContent())))
                          // setInput({
                          //   ...input,
                          //   descriptionState: e,
                          //   description: draftToHtml(convertToRaw(e.getCurrentContent())),
                          // });
                        }}
                        
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className="blog-cardfooter">
                <Link to="/blogs">
                  <Button className="cancel-btn">GO BACK</Button>
                </Link>
                <Button type="submit" className="primary-btn">SAVE DETAILS</Button>
              </Card.Footer>
            </form>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;