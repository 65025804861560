/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useNavigate, useParams, Link } from "react-router-dom";

import { Row, Col, Card, Button } from "react-bootstrap";

import ImageUploading from "react-images-uploading";

import Select from "react-select";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import moment from "moment";

import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as yup from "yup";

import { ContentState, convertToRaw, EditorState, convertFromHTML } from "draft-js";

/* APIS IMPORT */
import { getBlogCategories, addBlog, editBlog, getBlogById } from '../../../service/api'

import { IMAGE_URL } from "../../../config";

/* ICON IMPORTS */
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";



const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    discription: yup.string().required("Description is required."),
    category: yup.object().required("Category Required"),
});

const BlogEdit = () => {

    const params = useParams();

    const blogID = params.id;

    const [loading, setLoading] = useState(true);
    const [blogDetails, setBlogDetails] = useState(true);
    const [images, setImages] = useState('');
    const [blogDesc, setBlogDesc] = useState();
    const [blogCategories, setBlogCategories] = useState([])
    const [labelOptions, setLabelOptions] = useState([])

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            discription: ``,
            image: "",
            category: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append("name", values?.name);
            formData.append("imageName", blogDetails?.image);
            formData.append("discription", values?.discription);

            if (images) {
                formData.append("image", images);
            }

            formData.append("category", values?.category?.value);

            setLoading(true);

            editBlog(blogID, formData)
                .then((res) => {
                    if (res?.status) {
                        toast.success(res?.message);
                        // setAddBlogModal(false);
                        // getBlogData();
                        // setFile(null);
                        // setFile1(null);
                        // setPreviewUrl(null);
                        // setPreviewUrl1(null);
                        formik.resetForm();
                        setBlogDetails(null)

                    } else {
                        toast.error(res?.message);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error(e?.response?.data?.message);
                })
                .finally((res) => {
                    setLoading(false);
                    getBlogDetailsById()
                });
        },
    });

    useEffect(() => {
        getBlogsCategoryListFunction()
        getBlogDetailsById()
    }, [blogID]);

    /* GET BLOGS LISTING */
    const getBlogsCategoryListFunction = () => {
        setLoading(true);
        getBlogCategories().then((res) => {
            if (res?.status) {
                setBlogCategories(res?.data)
                // setT(res?.data?.totalDocs)
            }
            setLoading(false)
        })
            .catch(err => {
                console.log("err", err)
            })
            .finally(err => {
                setLoading(false)
            })

        return (() => {
            setBlogCategories([])
        })
    }

    const getBlogDetailsById = () => {
        getBlogById(blogID).then((res) => {
            setBlogDetails(res?.data)
        }).catch(err => {
            console.log("err", err)
        }).finally(err => {
            setLoading(false)
        })
    }
    const handleProfileImage = (imageList) => {
        setImages(imageList);
    };
    useEffect(() => {
        if (blogDetails !== null) {
            Object.entries(blogDetails).forEach((entry) => {
                const [key, value] = entry;
                if (validationSchema?.fields?.hasOwnProperty(key)) {
                    console.log(key, value)
                    if (key === 'category') {

                        formik.setFieldValue('category', labelOptions.filter((ele) => ele?.value === value?._id));
                    } else {
                        formik.setFieldValue(key, value);
                    }

                }
            });
        }


    }, [blogDetails]);

    useEffect(() => {
        if (blogCategories?.length > 0) {
            let tempArray = []
            blogCategories.forEach(element => {
                console.log(element)
                tempArray.push({ value: element?._id, label: element?.name },)
            })
            setLabelOptions(tempArray)
        }
    }, [blogCategories])

    return (
        <section className="blog-detail">
            {/* BACKGROUND BANNER */}
            <div className="bg-blue-banner" />

            <div className="container">
                {/* PAGE HEADING */}
                <div className="page-head">
                    <Row className="align-center">
                        <Col lg="12" sm="12">
                            <h1>How to Choose a Real Estate Agency to Work For</h1>
                        </Col>
                    </Row>
                </div>

                {/* BLOG DETAIL */}
                <div className="details">
                    <Card className="blog-card">
                        <form onSubmit={formik.handleSubmit} >
                            <Card.Body className="blog-cardbody">
                                <div className="row">
                                    <div className="col-xl-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fName">
                                                Blog Photo <span>*</span>
                                            </label>
                                            <ImageUploading
                                                value={images}
                                                onChange={(e) => { handleProfileImage(e) }}
                                                maxNumber={1}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemove,
                                                    onImageUpdate,
                                                }) => (
                                                    <div className="agent-profile-img">
                                                        <div
                                                            className="img-selection-box"
                                                            onClick={onImageUpload}
                                                        >
                                                            <div>
                                                                <ImportIcon size="20" color="#323232" />
                                                                <p>Upload from local computer</p>
                                                            </div>
                                                        </div>
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="uploaded-image">
                                                                <img
                                                                    src={image["data_url"]}
                                                                    alt="Uploaded Profile"
                                                                />
                                                                <div className="action-btns">
                                                                    <Button
                                                                        className="edit-btn"
                                                                        onClick={onImageUpdate}
                                                                    >
                                                                        <EditIcon size="15" color="#323232" />
                                                                    </Button>
                                                                    <Button
                                                                        className="remove-btn"
                                                                        onClick={onImageRemove}
                                                                    >
                                                                        <TrashIcon size="15" color="#323232" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                            <img
                                                className="blog-image"
                                                    src={IMAGE_URL + blogDetails?.image}
                                                    alt="Blog Images"
                                                />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Blog Title <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="How to choose a real estate agency to work for"
                                                name="name"
                                                id="blogTitle"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}

                                            />
                                            {formik.errors.name && formik.touched.name && (
                                                <small style={{ color: "red" }}>
                                                    {formik.errors.name}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Blog Labels <span>*</span>
                                            </label>
                                            <Select

                                                options={labelOptions}
                                                className="react-select"
                                                name="category"
                                                placeholder="Labels"
                                                classNamePrefix="action-select"
                                                value={formik.values.category}
                                                onChange={(e) => formik.setFieldValue('category', e)}
                                            />
                                            {formik.errors.category && formik.touched.category && (
                                                <small style={{ color: "red" }}>
                                                    {formik.errors.category}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-sm-12">
                                        <div className="form-group mb-0">
                                            <label className="form-label">
                                                Blog Description <span>*</span>
                                            </label>
                                            <Editor

                                                editorClassName="texteditor-control"
                                                toolbarClassName="toolbar-control"
                                                placeholder="Description about the blog"
                                                editorState={htmlToDraftBlocks(formik.values.discription)}

                                                onEditorStateChange={(e) => {
                                                    setBlogDesc(e);
                                                    formik.setFieldValue("discription", draftToHtml(convertToRaw(e.getCurrentContent())))
                                                    // setInput({
                                                    //   ...input,
                                                    //   descriptionState: e,
                                                    //   description: draftToHtml(convertToRaw(e.getCurrentContent())),
                                                    // });
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                            <Card.Footer className="blog-cardfooter">
                                <Link to="/blogs">
                                    <Button className="cancel-btn">GO BACK</Button>
                                </Link>
                                <Button type="submit" className="primary-btn">SAVE DETAILS</Button>
                            </Card.Footer>
                        </form>
                    </Card>
                </div>
            </div>
        </section>
    );
};

export default BlogEdit;