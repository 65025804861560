/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
  Spinner,
  Pagination,
} from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
// import sampleData from "./sampleData";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";

/* ICON IMPORTS */
import ExportIcon from "@iconscout/react-unicons/icons/uil-export";
import PlusIcon from "@iconscout/react-unicons/icons/uil-plus";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

// API import
import {
  addContactUser,
  deleteContactUser,
  editContactUser,
  getContactUser,
} from "../../../service/api";

const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else if (extractedString?.length === 0) {
    return string[0];
  } else {
    return extractedString[0];
  }
};

const actionOptions = [{ value: "Delete User", label: "Delete User" }];

const Users = () => {
  /* MODAL STATES */
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [type, setType] = useState("CONTACTUS");
  const [fetchedData, setFetchedData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [editid, setEditid] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const columns = [
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        <div className="user-detail">
          <div className="avatar">{findUpper(row?.name)}</div>
          <h5>{row.name}</h5>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <span className={row?.phone ? "light-text" : "light-text"}>
          {row?.phone ? row?.phone : "-"}
        </span>
      ),
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row.email}</span>,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditUserModal(!editUserModal);
              setEditid(row?._id);
              setMessage(row?.message);
              setFirstName(row?.name);
              setEmail(row?.email);
              setPhone(row?.phone);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteModal(!deletemodal);
              setEditid(row?._id);
            }}
          />
        </div>
      ),
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const paginationComponentOptions = {
    sizePerPage: sizePerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    paginationSize: 3,
    showTotal: true,
    paginationTotalRenderer: ({ from, to, count }) => (
      <div className="react-bootstrap-table-pagination-total ml-3">
        Showing {from} to {to} of {count} entries
      </div>
    ),
  };

  const handleFilter = (event) => {
    const newData = filterData.filter((row) =>
      row?.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFetchedData(newData);
  };

  const handleDelete = () => {
    deleteContactUser(editid)
      .then((res) => {
        getUserData();
        console.log(res);
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deletemodal);
      });
  };

  const handleEdit = () => {
    // console.log("this is handle edit");
    let formData = new FormData();
    let name = firstName + " " + lastName;
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("type", type);
    formData.append("phone", phone);
    const formDataIterator = formData.entries();
    for (const [key, value] of formDataIterator) {
      console.log(`${key}: ${value}`);
    }
    console.log(editid);
    editContactUser(editid, formData)
      .then((res) => {
        // setLoading(true);
        console.log(res);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setEditUserModal(!editUserModal);
        getUserData();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((res) => {
        // setLoading(false);
      });
  };

  const getUserData = () => {
    const params = {
      page: page + 1,
      sizePerPage: sizePerPage,
      type: type,
    };
    getContactUser(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        setFilterData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      });
  };

  // const displayData = () => {
  //   setUserData([]);
  //   fetchedData.map((dataitem) => {
  //     if (userData.length < fetchedData.length) {
  //       setUserData(
  //         userData.concat({
  //           id: dataitem?._id,
  //           userName: dataitem?.name,
  //           email: dataitem?.email,
  //           phone: "12345678",
  //           joiningDate: "Jan 01, 2023",
  //           points: "1200",
  //           message: dataitem?.message,
  //         })
  //       );
  //     }
  //   });
  // };

  // fetchedData.map((dataitem) => {
  //   if (userData.length < fetchedData.length) {
  //     setUserData(
  //       userData.concat({
  //         id: dataitem?._id,
  //         userName: dataitem?.name,
  //         email: dataitem?.email,
  //         phone: "12345678",
  //         joiningDate: "Jan 01, 2023",
  //         points: "1200",
  //         message: dataitem?.message,
  //       })
  //     );
  //   }
  // });

  useEffect(() => {
    getUserData();
  }, [page, sizePerPage, type]);

  return (
    <section className="users">
      <ToastContainer />
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Contacts List</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your contacts and edit their information
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                <Button className="import-btn">
                  <ExportIcon size="14" color="#323232" className="btn-icon" />
                  Export
                </Button>
                {/* <Button
                  className="add-btn"
                  onClick={() => setAddUserModal(!addUserModal)}
                >
                  <PlusIcon size="20" color="#323232" />
                </Button> */}
              </div>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              {/* <div className="left-header">
                <Select
                  options={actionOptions}
                  className="react-select"
                  placeholder="Action"
                  classNamePrefix="action-select"
                />
                <Button className="border-btn">Apply</Button>
              </div> */}
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={handleFilter}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              {fetchedData?.length === 0 ? (
                <Spinner variant="warning" />
              ) : (
                <DataTable
                  columns={columns}
                  data={fetchedData}
                  // selectableRows
                  pagination
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal
        centered
        show={deletemodal}
        onHide={() => setDeleteModal(!deletemodal)}
      >
        <Modal.Body>
          <p>Are you sure you want to Delete this Contact?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setDeleteModal(!deletemodal)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ADD USER MODAL */}
      <Modal
        centered
        size="md"
        show={addUserModal}
        onHide={() => setAddUserModal(!addUserModal)}
      >
        <Modal.Header className="add-user-modal-header">
          <h5>Add Contact</h5>
        </Modal.Header>
        <Modal.Body>
          <form className="add-user-form">
            <Row>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fName"
                    id="fName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="lName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lName"
                    id="lName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="placeholder@amberwoodre.com"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    className="phone-control"
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setAddUserModal(!addUserModal)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn">Add contact</Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT USER MODAL */}
      <Modal
        centered
        size="md"
        show={editUserModal}
        onHide={() => setEditUserModal(!editUserModal)}
      >
        <Modal.Header className="add-user-modal-header">
          <h5>Edit Contact</h5>
        </Modal.Header>
        <Modal.Body>
          <form className="add-user-form">
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="fName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
              </Col>
              {/* <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="lName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lName"
                    id="lName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                  />
                </div>
              </Col> */}
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="placeholder@roundtechsquare.com"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    className="phone-control"
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setEditUserModal(!editUserModal)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleEdit}>
            Save details
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Users;
