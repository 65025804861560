/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
  Spinner,
  Pagination,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";

/* ICON IMPORTS */
import PreviousIcon from "@iconscout/react-unicons/icons/uil-arrow-left";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

/* API IMPORTS */
import {
  getRSVPList,
  editRSVPMember,
  deleteRSVPMember,
  getEventByID,
} from "../../../service/api";

const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else if (extractedString?.length === 0) {
    return string[0];
  } else {
    return extractedString[0];
  }
};

const RSVPList = () => {
  const params = useParams();
  const navigate = useNavigate();

  /* MODAL STATES */
  const [editMemberDetails, setEditMemberDetails] = useState(false);
  const [deleteMemberDetails, setDeleteMemberDetails] = useState(false);

  /* API STATES */
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [eventID, setEventID] = useState(params.id);
  const [rsvpListData, setRSVPListData] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [eventData, setEventData] = useState(null);

  /* EDIT DETAILS STATE */
  const [editID, setEditID] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [peopleAttending, setPeopleAttending] = useState("");

  /* EVENT DATA */
  const getEventData = () => {
    getEventByID(eventID)
      .then((res) => {
        setEventData(res?.data);
      })
      .catch((e) => {
        console.log("e-->", e);
      });
  };

  /* GET RSVP LIST */
  const getRSVPData = () => {
    const params = {
      page: page + 1,
      sizePerPage: sizePerPage,
      event: eventID,
    };
    getRSVPList(params)
      .then((res) => {
        setRSVPListData(res?.data?.docs);
        setFilterData(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      });
  };

  /* EDIT DETAILS */
  const handleEditDetails = () => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("peopleAttending", peopleAttending);
    formData.append("eventID", eventID);
    editRSVPMember(editID, formData)
      .then(() => {
        setName("");
        setEmail("");
        setPhone("");
        setPeopleAttending("");
        setEditMemberDetails(!editMemberDetails);
        toast.success("The details have been updated successfully");
        getRSVPData();
      })
      .catch((e) => {
        console.log("Error: ", e);
      })
      .finally((res) => {
        console.log("Res: ", res);
      });
  };

  /* DELETE DETAILS */
  const handleDeleteDetails = () => {
    deleteRSVPMember()
      .then(() => {
        getRSVPData();
        toast.success("The details have been deleted successfully");
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteMemberDetails(!deleteMemberDetails);
      });
  };

  /* SEARCH DETAILS */
  const handleSearch = (event) => {
    const newData = filterData.filter((row) =>
      row?.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setRSVPListData(newData);
  };

  /* ONLOAD DATA */
  useEffect(() => {
    getRSVPData();
  }, [page, sizePerPage, eventID]);

  useEffect(() => {
    getEventData();
  }, [eventID])

  /* TABLE COLUMNS */
  const columns = [
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        <div className="user-detail">
          <div className="avatar">{findUpper(row?.name)}</div>
          <h5>{row.name}</h5>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <span className={row?.phone ? "light-text" : "light-text"}>
          {row?.phone ? row?.phone : "-"}
        </span>
      ),
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row.email}</span>,
    },
    {
      name: "People Attending",
      selector: (row) => (
        <span className="light-text" style={{ textAlign: "center" }}>
          {row.noOfPeopleAttending}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditMemberDetails(!editMemberDetails);
              setEditID(row?._id);
              setName(row?.name);
              setEmail(row?.email);
              setPhone(row?.phone);
              setPeopleAttending(row?.noOfPeopleAttending);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteMemberDetails(!deleteMemberDetails);
              setEditID(row?._id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="users">
      <ToastContainer />
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>RSVP List ({eventData?.name})</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through the people attending your event.
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                <Button className="import-btn" onClick={() => navigate(-1)}>
                  Back to Events
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* RSVP LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <p>
                Showing <b>{rsvpListData?.length}</b> guests details
              </p>
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={handleSearch}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable columns={columns} data={rsvpListData} pagination />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deleteMemberDetails}
        onHide={() => setDeleteMemberDetails(!deleteMemberDetails)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete these guest details ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setDeleteMemberDetails(!deleteMemberDetails)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDeleteDetails}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT USER MODAL */}
      <Modal
        centered
        size="md"
        show={editMemberDetails}
        onHide={() => setEditMemberDetails(!editMemberDetails)}
      >
        <Modal.Header className="add-user-modal-header">
          <h5>Edit Contact</h5>
        </Modal.Header>
        <Modal.Body>
          <form className="add-user-form">
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="fName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="placeholder@roundtechsquare.com"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    className="phone-control"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    No. of People Attending
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="peopleAttending"
                    id="peopleAttending"
                    value={peopleAttending}
                    onChange={(e) => setPeopleAttending(e.target.value)}
                    placeholder="10"
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setEditMemberDetails(!editMemberDetails)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleEditDetails}>
            Save details
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default RSVPList;
